import ng from 'angular';

import BrandsListComponent from './brands-list.component';
import { brandConnectivityModule } from './brand-connectivity/brand-connectivity.module';
import { RiskManagerModule } from './risk-manager/risk-manager.module';
import { CommunicationsModule } from './communications/communications.module';
import { integrationsRouting } from './integrations.routing';
import { SystemConfigurationsModule } from './system-configurations/system-configurations.module';

export const IntegrationsModule = ng
  .module('crm.management.integrations', [
    brandConnectivityModule.name,
    RiskManagerModule.name,
    CommunicationsModule.name,
    SystemConfigurationsModule.name,
  ])
  .component('prfBrandsList', BrandsListComponent)
  .config(integrationsRouting);
