import RestService from '~/source/common/services/rest';
import { ClientGeneralPubsub } from './client-general-pubsub';
import restangular, { IPromise } from 'restangular';
import { CrmExport } from '@proftit/crm.api.models.entities';

const DOWNLOAD_REPORTS = 'downloadReports';
const DOWNLOADS = 'downloads';

export class CRMExportService extends RestService {
  static $inject = [...RestService.$inject, 'prfClientGeneralPubsub'];

  prfClientGeneralPubsub: ClientGeneralPubsub;

  /**
   *  Return resource name
   *
   * @returns resource name.
   */
  get resource() {
    return 'CRMExport';
  }

  getExportCustomerTableCsvResource() {
    return this.resourceBuildStart()
      .getElement(DOWNLOAD_REPORTS)
      .resourceBuildEnd();
  }

  /**
   * Request export customer table to csv
   *
   * @param exportIndex
   * @param columnDefinitions
   * @param queryUrl
   * @returns work promise
   */
  generateExportCustomerTableCsv(
    exportIndex: string,
    columnDefinitions: any[],
    queryUrl: string,
  ): Promise<CrmExport> {
    return this.getExportCustomerTableCsvResource().customPostWithQuery({
      exportIndex,
      columnDefinitions,
      queryUrl,
    });
  }

  getDownloadReportPath(reportId: number) {
    return `${DOWNLOAD_REPORTS}/${reportId}/${DOWNLOADS}`;
  }

  downloadReport(reportId: number) {
    const customPath = this.getDownloadReportPath(reportId);
    return this.getWithCustomPath(customPath, { responseType: 'blob' });
  }
}
