import ng from 'angular';
import settings from './reports-settings.json';
import KibiContainerPageComponent from './kibi-container-page.component';
import ReportsIframeLoader from './reports-iframe-loader.component';
import UserReportGenerationDownloadListComponent from './user-report-generation-download-list/user-report-generation-download-list.component';
import UserReportGenerationDownloadHandlerComponent from './user-report-generation-download-handler/user-report-generation-download-handler.component';
import GenericProgressBoxComponent from './generic-progress-box/generic-progress-box.component';
import KibiDownloadsService from './kibi-downloads.service';
import { CrmDownloadsService } from './crm-downloads.service';
import DownloadsPopupService from './downloads-popup.service';
import KibiDownloadSocketService from './kibi-download-socket.service';
import { CrmDownloadsSocketService } from './crm-downloads-socket.service';
import { BalanceLogDownloadsService } from '~/source/reports/balance-log-downloads.service';

export default ng
  .module('crm.reports', ['crm.constants', 'crm.common', 'ui.router'])
  .constant('reportsSettings', settings)
  .component('prfReportsIframeLoader', ReportsIframeLoader)
  .component('prfKibiContainerPage', KibiContainerPageComponent)
  .component(
    'prfUserReportGenerationDownloadList',
    UserReportGenerationDownloadListComponent,
  )
  .component(
    'prfUserReportGenerationDownloadHandler',
    UserReportGenerationDownloadHandlerComponent,
  )
  .component('prfGenericProgressBox', GenericProgressBoxComponent)
  .service('kibiDownloadsService', KibiDownloadsService)
  .service('crmDownloadsService', CrmDownloadsService)
  .service('downloadsPopupService', DownloadsPopupService)
  .service('kibiDownloadSocketService', KibiDownloadSocketService)
  .service('crmDownloadsSocketService', CrmDownloadsSocketService)
  .factory(
    'balanceLogDownloadsService',
    BalanceLogDownloadsService.$factorize(),
  )
  .config([
    '$stateProvider',
    ($stateProvider) => {
      $stateProvider.state('crm.reports', {
        url: '/reports',
        component: 'prfKibiContainerPage',
        data: {
          permissions: {
            only: ['reporting'],
            redirectTo: 'error.unauthorized',
          },
          pageTitle: 'REPORTS',
        },
      });
    },
  ]).name;
