import ng from 'angular';

import BooleanSelect from './boolean-select.component';
import DisableNotificationsSelect from './disable-notifications-select.component';
import RestrictionSelect from './restriction-select.component';
import BrandPlatformSelect from './brand-platform-select.component';
import BrandSelect from './brand-select.component';
import CampaignSelect from './campaign-select.component';
import CampaignIdSelect from './campaign-id-select.component';
import ClearingCompanySelect from './clearing-companies-select/clearing-companies-select.component';
import CountrySelect from './country-select.component';
import CurrencySelect from './currency-select.component';
import CryptoCurrencySelect from './crypto-currency-select.component';
import DeskSelect from './desk-select.component';
import { DeskAllSelectComponent } from './desk-all-select.component';
import ExperienceRangesSelect from './experience-ranges-select.component';
import ExternalDataSelect from './external-data-select/external-data-select';
import IsActiveSelect from './is-active-select.component';
import LanguageSelect from './language-select.component';
import MinuteSelect from './minute-select.component';
import PendingPositionsStatusesForexSelect from './pending-position-statuses-forex-select';
import PlatformSelect from './platform-select.component';
import PlatformTypesSelect from './platform-types-select.component';
import AllowedPlatformTypesSelect from './allowed-platform-types-select.component';
import PricingModelSelect from './pricing-model-select.component';
import StateSelect from './state-select.component';
import TimeZoneSelect from './time-zone-select.component';
import TradingAccountStatusesSelect from './trading-account-status-select.component';
import TransactionStatusesSelect from './transaction-statuses-select.component';
import EWalletTransactionStatusesSelect from './ewallet-transaction-statuses-select.component';
import TransferMethodTypesSelect from './transfer-method-types-select.component';
import DataExportReportIndexTypeSelect from './data-export-report-index-type-select.component';

import UsernameSelect from './username-select.component';
import UserSelect from './user-select.component';
import VoipProviderSelect from './voip-provider-select.component';
import WithdrawalTypesSelect from './withdrawal-types-select.component';
import NumOfRowsSelect from './number-of-rows-select/number-of-rows-select.component';
import AttachmentActionSelect from './attachment-action-select.component';
import { CfdPlatformCurrencyNameSelectComponent } from './cfd-platform-currency-name-select';
import { InactivityFeeChargeTypeSelectComponent } from './inactivity-fee-charge-type-select.component';
import { ContentTemplateSelectComponent } from './content-template-select.component';
import { CustomerContentTemplateSelectComponent } from './customer-content-templates-select.component';
import { CustomerLinksSelectComponent } from './customer-links-select.component';
import { ContentTemplateTypesSelectComponent } from './content-template-types-select.component';
import { QuestionnaireFormSelectComponent } from './questionnaire-form-select.component';
import { SmsProviderSelectComponent } from './sms-provider-select.component';
import { SystemEmailTemplateContentTemplateAsLanguageComponent } from './system-email-template-content-template-as-language-select.component';
import { AvailableLanguagesForSendForBrandSelectComponent } from './available-languages-for-send-for-brand-select.component';
import { ActiveDesignTemplateSelectComponent } from './active-design-template-select.component';
import { EmailProviderSelectComponent } from '~/source/common/components/dropdowns/email-provider-select.component';
import { CreatedByEnumSelectComponent } from './created-by-enum-select.component';
import { BonusProcessByUserSelectComponent } from './bonus-process-by-user-select.component';
import { UserWithSystemSelectComponent } from '~/source/common/components/dropdowns/user-with-system-select.component';

export const DropdownsModule = ng
  .module('common.dropdowns', [])
  .component(
    'prfActiveDesignTemplateSelect',
    ActiveDesignTemplateSelectComponent,
  )
  .component('prfBooleanSelect', BooleanSelect)
  .component('prfDisableNotificationsSelect', DisableNotificationsSelect)
  .component('prfRestrictionSelect', RestrictionSelect)
  .component('prfBrandPlatformSelect', BrandPlatformSelect)
  .component('prfBrandSelect', BrandSelect)
  .component('prfCampaignSelect', CampaignSelect)
  .component('prfCampaignIdSelect', CampaignIdSelect)
  .component('prfClearingCompaniesSelect', ClearingCompanySelect)
  .component('prfCountrySelect', CountrySelect)
  .component('prfCurrencySelect', CurrencySelect)
  .component('prfCryptoCurrencySelect', CryptoCurrencySelect)
  .component('prfDeskSelect', DeskSelect)
  .component('prfDeskAllSelect', DeskAllSelectComponent)
  .component('prfExperienceRangesSelect', ExperienceRangesSelect)
  .component('prfExternalDataSelect', ExternalDataSelect)
  .component('prfIsActiveSelect', IsActiveSelect)
  .component('prfLanguageSelect', LanguageSelect)
  .component('prfMinuteSelect', MinuteSelect)
  .component(
    'prfPendingPositionsStatusesForexSelect',
    PendingPositionsStatusesForexSelect,
  )
  .component('prfPlatformSelect', PlatformSelect)
  .component('prfPlatformTypesSelect', PlatformTypesSelect)
  .component('prfAllowedPlatformTypesSelect', AllowedPlatformTypesSelect)
  .component('prfPricingModelSelect', PricingModelSelect)
  .component('prfStateSelect', StateSelect)
  .component('prfTimeZoneSelect', TimeZoneSelect)
  .component('prfTradingAccountStatusSelect', TradingAccountStatusesSelect)
  .component('prfTransactionStatusesSelect', TransactionStatusesSelect)
  .component(
    'prfEwalletTransactionStatusesSelect',
    EWalletTransactionStatusesSelect,
  )
  .component('prfTransferMethodTypesSelect', TransferMethodTypesSelect)
  .component('prfDataExportReportIndexSelect', DataExportReportIndexTypeSelect)
  .component('prfUsernameSelect', UsernameSelect)
  .component('prfUserSelect', UserSelect)
  .component('prfVoipProviderSelect', VoipProviderSelect)
  .component('prfSmsProviderSelect', SmsProviderSelectComponent)
  .component('prfEmailProviderSelect', EmailProviderSelectComponent)
  .component(
    'prfSystemEmailTemplateContentTemplateAsLanguageSelect',
    SystemEmailTemplateContentTemplateAsLanguageComponent,
  )
  .component('prfWithdrawalTypesSelect', WithdrawalTypesSelect)
  .component('prfAttachmentActionSelect', AttachmentActionSelect)
  .component('prfNumOfRowsSelect', NumOfRowsSelect)
  .component(
    'prfInactivityFeeChargeTypeSelect',
    InactivityFeeChargeTypeSelectComponent,
  )
  .component('prfContentTemplateSelect', ContentTemplateSelectComponent)
  .component(
    'prfCustomerContentTemplateSelect',
    CustomerContentTemplateSelectComponent,
  )
  .component('prfCustomerLinksSelect', CustomerLinksSelectComponent)
  .component(
    'prfContentTemplateTypesSelect',
    ContentTemplateTypesSelectComponent,
  )
  .component('prfQuestionnaireFormSelect', QuestionnaireFormSelectComponent)
  .component(
    'prfAvailableLanguagesForSendForBrandSelect',
    AvailableLanguagesForSendForBrandSelectComponent,
  )
  .component('prfCreatedByEnumSelect', CreatedByEnumSelectComponent)
  .component('prfBonusProcessByUserSelect', BonusProcessByUserSelectComponent)
  .component('prfUserWithSystemSelect', UserWithSystemSelectComponent)
  .component(
    'prfCfdPlatformCurrencyNameSelect',
    CfdPlatformCurrencyNameSelectComponent,
  );
