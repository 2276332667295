export const DefaultsAppConfig: IAppConfig = {
  requestCache: true,
  defaultLogLevel: 'silent',
  apiPrefix: {
    user: 'api/user',
    customer: 'api/customer',
    public: 'api/public',
    provider: 'api/provider',
    legacy: '',
  },
  defaultLang: 'en',
  defaultTimezone: 'UTC',
  baseCurrency: {
    code: 'USD',
    sign: '$',
  },
  defaultState: 'crm.accounting',
  pluginUrls: {
    bundle: 'https://widgets.[COMPANY].proftit.com/latest/prf.widgets.js',
    css: 'https://widgets.[COMPANY].proftit.com/latest/prf.widgets.css',
  },
  debugInfoEnabled: false,
  connections: {
    api: '',
    streamer: '',
  },
  intercomConfig: {
    app_id: '',
  },
  simplelocalizeClientName: null,
  simplelocalizeBaseDomain: null,
};

export interface IAppConfig {
  connections: {
    api: string;
    streamer: string;
  };
  intercomConfig: {
    app_id: string;
  };
  requestCache: boolean;
  defaultLogLevel: string;
  apiPrefix: {
    user: string;
    customer: string;
    public: string;
    provider: string;
    legacy: string;
  };
  defaultLang: string;
  defaultTimezone: string;
  baseCurrency: {
    code: string;
    sign: string;
  };
  defaultState: string;
  pluginUrls: {
    bundle: string;
    css: string;
  };
  debugInfoEnabled: boolean;
  simplelocalizeBaseDomain: string;
  simplelocalizeClientName: string;
}

export default DefaultsAppConfig;
