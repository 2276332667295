import * as _ from '@proftit/lodash';

export enum PermissionNormalized {
  AutomationDemoDeposit = 'automation.demo_deposit',
  AutoAssignments = 'automation.autoassignment',
  AssignmentPannel = 'management.assignmentpanel',
  ContactsAttachments = 'contacts.attachments',
  ContactsFileUpload = 'contacts.files',
  ContactsCommunicationsRecordings = 'contacts.communications.recordings',
  ContactsCommunications = 'contacts.communications',
  ContactsPersonalInfoAssignTo = 'contacts.personalinfo.assignto',
  ContactsLinkCustomers = 'contacts.linkcustomers',
  ContactsContactImport = 'contacts.contactimport',
  ContactPersonalInfoPhone = 'contacts.personalinfo.phone',
  ContactPersonalInfoEmail = 'contacts.personalinfo.email',
  ContactPersonalInfoLoginAsCustomer = 'contacts.personalinfo.loginas',
  ContactPersonalInfoSharedGroupFilters = 'contacts.personalinfo.sharedgroupfilters',
  ContactsCommunicationsEmailSendFrom = 'contacts.communications.email_send_from',
  ContactsTradingAccountType = 'contacts.tradingaccount.type',
  ContactsTradingAccountResetMt4Password = 'contacts.tradingaccount.mt4pswd',
  ContactsSend2faCode = 'contacts.send2facode',
  ContactsPersonalInfoDisable2fa = 'contacts.personalinfo.disable_2fa',
  ContactsWhatsappweb = 'contacts.whatsappweb',
  ManagementBrandCommunicationSettings = 'management.brands.communication_settings',
  ManagementSystemConfiguration = 'management.system_configurations',
  ManagementEmailTemplate = 'management.email_templates',
  ManagementRiskmanager = 'management.riskmanager',
  ManagementFieldsAndModules = 'management.fields_and_modules',
  Marketing = 'marketing',
  MarketingCampaigns = 'marketing.campaigns',
  AutomationInactivity = 'automation.inactivity',
  AutomationAccountStatus = 'automation.account_statuses',
  AutomationAccountStatusPermissions = 'automation.account_status_permissions',
  AutomationReferFriend = 'automation.referfriend',
  AutomationBrandPlatformPromoCode = 'automation.brandPlatformPromoCode',
  Portfolio = 'portfolio',
  ContactsCrmexport = 'contacts.crmexport',
  ContactsPersonalInfoStatus = 'contacts.personalinfo.status',
  ContactsPersonalInfoComplianceStatus = 'contacts.personalinfo.compliancestatus',
  ContactsPersonalInfoName = 'contacts.personalinfo.name',
  ReportingKibiExport = 'reporting.kibiexport',
  BalanceLogExport = 'contacts.tradingaccount.balance_logs_export',
  Exposure = 'exposure',
  ContactsCommunicationsExportCommunicationsHistory = 'contacts.communications.export_communication_history',
  Support = 'support',
}

interface PermissionOptions {
  Create: string;
  Read: string;
  Update: string;
  Delete: string;
}

function generatePermissionOptions(permission: string): PermissionOptions {
  return {
    Create: `${permission}_C`,
    Read: `${permission}`,
    Update: `${permission}_U`,
    Delete: `${permission}_D`,
  };
}

/**
 * https://github.com/Microsoft/TypeScript/issues/14294
 * https://stackoverflow.com/questions/55377365/what-does-keyof-typeof-mean-in-typescript
 */
export const Permissions: {
  [key in keyof typeof PermissionNormalized]: PermissionOptions;
} = _.flow([
  () => _.toPairs(PermissionNormalized),
  (pairs) =>
    pairs.map(([permKey, permValue]) => [
      permKey,
      generatePermissionOptions(permValue),
    ]),
  (pairs) => _.fromPairs(pairs),
])();
