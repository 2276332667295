import angular from 'angular';

import FilterLabelComponent from './filter-label/filter-label.component';
import TableFilterComponent from './filters-bar/filters-bar.component';
import { FilterPopoverModule } from './filter-popover/module';
import filterSettings from './filters.json';
import FilterGroupModule from './filter-group/module';
import FilterService from './services/filter.service';
import FilterCacheService from './services/filter-cache';
import filterKeyDuplicationResolverService from './services/filter-key-duplication-resolver';

export const TableFiltersModule = angular.module('crm.common.tableFilters', [
  FilterGroupModule,
  FilterPopoverModule.name,
]);

TableFiltersModule.component('prfFilterLabel', FilterLabelComponent)
  .component('prfTableFiltersBar', TableFilterComponent)
  .constant('filtersSettings', filterSettings)
  .service('filterService', FilterService)
  .service('filterCache', FilterCacheService)
  .service('filterKeyDuplicationResolver', filterKeyDuplicationResolverService);
