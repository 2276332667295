// tslint:disable:max-line-length

/**
 * Table Controller.
 * we use ng-table in the project, this controller initialize ng-table params
 */
import _ from 'underscore';
import Controller from '~/source/common/controllers/base';
import RestService, {
  IRestServiceInstance,
} from '~/source/common/services/rest';
import UiRouter from '@uirouter/core';
import TableCacheService from '~/source/common/services/table-cache.service';
import ICollectionRestNg from '~/source/common/models/icollection-rest-ng';
import { NgTableParams } from 'ng-table';
import { Entity } from '@proftit/crm.api.models.general';
import * as rx from '@proftit/rxjs';

export abstract class TableController<
  T extends IRestServiceInstance = IRestServiceInstance,
  S extends Entity = Entity
> extends Controller {
  static $inject = [
    '$timeout',
    '$stateParams',
    'NgTableParams',
    'filterService',
    'CacheFactory',
    'filterCache',
    'filtersSettings',
    'tableCacheService',
    ...Controller.$inject,
  ];

  // 3rd party Services
  $timeout: angular.ITimeoutService;
  $stateParams: UiRouter.StateParams;
  $scope: angular.IScope;
  CacheFactory: CacheFactory.ICacheFactory;
  tableParams: NgTableParams<any>;
  NgTableParams: typeof NgTableParams;
  // Our services (not typed yet)
  filterCache: any;
  filterService: any;
  filtersSettings: any;
  tableCacheService: TableCacheService;
  isInitTable$ = new rx.BehaviorSubject<boolean>(false);
  blockUiKey: string;
  growlRef: string;
  tableCacheKey: string;
  // Infinite scrolling settings
  getDataInProgress: boolean;
  isScrolling: boolean;
  noDataAvailable: boolean;
  infiniteScrollSettings: any;
  collection: ICollectionRestNg<S>;
  collection$ = new rx.BehaviorSubject<S[]>([]);

  isFilterOpen: boolean;
  filterType: string;
  chosenFilter: string;
  skipDealFilters: boolean;
  dataServiceInstance: T;
  settings: any = {};

  // Checkbox properties for NgTable with Checkbox.
  checkboxes = {
    showSelectAllMsg: false, // Show/hide select all options, when user select all checkboxes per page
    selectAllItems: false, // select /reset Option select all records in database
    checked: false, // selectAll/unSelectAll checkbox in header
    selected: {}, // selected object add dynamic properties for checkbox
    selectedToList: [], // returns an array of the allCheckboxes object properties
  };

  // Table properties for NgTable with Checkbox.
  tableData = {
    currentPageResult: [],
    totalRecords: 0,
  };

  // filter containers
  quickFilters = {};

  // holds updated state of normalized filters
  normalizedFilters = {};

  /*
   * Represent an activation function of the tableParams filter function, that is binded to
   * the current tableParams instance.
   *
   * A method function structure would not have retain the context binding when passing it's
   * reference, so we use an arrow function that retain the outer 'this' context.
   */
  tableParamsFilter = () => this.tableParams.filter();

  // cashed requested url to send as url param when requesting table csv report
  outgoingRequestUrl: string = null;

  constructor(...args: any[]) {
    super(...args);
  }

  $onInit() {
    if (!this.skipDealFilters) {
      this.$scope.$on('table:filter:add', (evt, ...args) => {
        this.bubbleDown(evt, ...args);
      });
    }

    this.$scope.$on('table:filter:updated', (evt, argA) => {
      this.onFiltersChange(evt, argA);
    });

    if (this.tableKey) {
      this.blockUiKey = `${this.tableKey}BlockUi`;
      this.growlRef = `${this.tableKey}Growl`;
      this.tableCacheKey = `${this.tableKey}TableParams`;
    }

    // set infinite scroll table settings in-case it was defined in the child controller
    if (this.isInfiniteTable) {
      this.setInfiniteScrollSettings();
    }
  }

  /**
   * unique table key that will be used to create cache keys for table. filters & block-ui
   * @returns {string}
   */
  get tableKey(): string {
    return '';
  }

  /**
   * enable/disable infinite scroll for this table
   * @returns {boolean} True will add infinite scroll settings to 'this'
   */
  get isInfiniteTable(): boolean {
    return false;
  }

  /**
   * set infinite scroll table settings
   */
  setInfiniteScrollSettings() {
    Object.assign(this, {
      isScrolling: false,
      infiniteScrollSettings: {
        throttlesFn: _.debounce(this.loadMore.bind(this), 500, true),
        disabledFn: this.isLoadMoreDisabled.bind(this),
        distance: '0.1',
        parent: 'true',
        immediateCheck: 'true',
      },
    });
  }

  /**
   * Number of records to add when infinite-scroll calls "load more"
   * @returns {number}
   */
  get loadMoreCount(): number {
    return 10;
  }

  /**
   * Returns true if "load more" should currently be disabled:
   * - If a fetch is currently in progress
   * - If there was no data returned in the last api call
   * - If the number of records fetched already exceeded the total records
   * @returns {boolean}
   */
  isLoadMoreDisabled(): boolean {
    const { start, limit } = this.getTableQueryLimits();

    return (
      this.getDataInProgress ||
      this.noDataAvailable ||
      (this.tableData.totalRecords &&
        start + limit >= this.tableData.totalRecords)
    );
  }

  /**
   * Change to "scroll mode" and increase the table count (num records to display in page)
   */
  loadMore() {
    this.isScrolling = true;
    this.tableParams.page(this.tableParams.page() + 1);
  }

  /**
   * get start & limit variables for table query
   * @returns {{start: number, limit: number}}
   */
  getTableQueryLimits(): { start: number; limit: number } {
    // values are zeroed when tableParams are not initialized
    if (!this.tableParams) {
      return {
        start: 0,
        limit: 0,
      };
    }

    let limit: number = this.tableParams.count();
    let start: number =
      (this.tableParams.page() - 1) * this.tableParams.count();

    // set limits for scrolling mode
    if (this.isScrolling && this.tableParams.page() !== 1) {
      limit = this.loadMoreCount;
      // Scroll mode: skip previous count & loadedMore records
      start =
        this.tableParams.count() +
        (this.tableParams.page() - 2) * this.loadMoreCount;
    }

    return {
      start,
      limit,
    };
  }

  /**
   * init ngTable params
   */
  initTable(calculateNumberOfRowsToLoad?: number) {
    // table settings
    const ngTableSettings = Object.assign({}, this.ngTableSettings.settings, {
      getData: this.getData.bind(this),
    });
    // merge filters from filter cache & config file
    const filter = this.isCachedFilterAvailable()
      ? { ...this.getNormalizedFilters() }
      : { ...(this.ngTableSettings.parameters.filter || {}) };
    // destruct table params from config file & local storage with merged filter object
    const tableParams = {
      ...this.ngTableSettings.parameters,
      ...(this.getLocalStorageTableParams() || {}),
      filter,
    };

    this.$scope.$evalAsync(() => {
      if (this.infiniteScrollSettings && calculateNumberOfRowsToLoad) {
        tableParams.count = calculateNumberOfRowsToLoad + 2;
      }

      if (!this.infiniteScrollSettings && calculateNumberOfRowsToLoad) {
        tableParams.count = calculateNumberOfRowsToLoad;
      }
      this.tableParams = new this.NgTableParams(tableParams, ngTableSettings);
      this.isInitTable$.next(true);
    });
  }

  /**
   * A settings object which will be used to create "ngTableParams" instance (which configures the ngTable).
   * Used on initTable method.
   *
   * @example
   * "parameters": {
   *   "count": 4,
   *   "page": 1
   * },
   * "settings": {
   *   "counts": []
   * }
   * @see {@link: https://github.com/esvit/ng-table/wiki/Configuring-your-table-with-ngTableParams/7855cacbdf2f02aa6e9a1e9d6871a93285781461}
   * @return {any}
   */
  get ngTableSettings(): any {
    return {};
  }

  /**
   * If ngTable was initialized already, this will be the ngTableParams instance
   * (which was configured by ngTableSettings)
   * @return {any}
   */
  get ngTableDataParams(): any {
    return this.tableParams;
  }

  abstract fetchFn(): RestService;

  /**
   * Default getData function used by ngTable.
   *
   * Requires a "fetchFn" function to be defined by subclass, which would return
   * an instance of the rest service, with the needed configuration.
   *
   * @param {object} params
   */
  getData(
    params: NgTableParams<Restangular.IElement>,
  ): Promise<Restangular.IElement[]> {
    const { limit, start } = this.getTableQueryLimits();
    const filter = Object.assign(
      {},
      this.tableParams.filter(),
      this.requiredApiFilters,
    );

    // save table query parameters to local storage
    this.setLocalStorageTableParams();

    // we need this indicator to avoid duplicate fetches
    this.getDataInProgress = true;

    return this.fetchFn()
      .slice(start, undefined, limit)
      .filter(filter)
      .sort(params.sorting())
      .getListWithQuery<Restangular.IElement>()
      .then((data) => {
        let resultData = data;
        // parse loaded data
        this.parseLoadedData(resultData, this.dataServiceInstance.total);

        // set no data available for loadMoreDisabled
        this.noDataAvailable = resultData.length === 0;

        // set the totals to ng-params
        params.total(this.dataServiceInstance.total);

        if (this.isScrolling && start !== 0) {
          this.collection.push(...resultData);
          resultData = this.collection;
        }

        // set new collection result and totals records to the table data
        this.tableData.currentPageResult = resultData;
        this.tableData.totalRecords = this.dataServiceInstance.total;

        /*
         * call onLoadData, check if all Checkbox list are Checked in Current page,
         * then set checkboxAll element in header to true
         */
        this.onLoadData();

        this.collection = resultData;
        this.collection$.next(
          this.collection.plain ? this.collection.plain() : this.collection,
        );

        /*
         * should be equal or greater than loadMore debounce length (defined in the constructor).
         * otherwise, slow connection users will suffer inconsistent behavior in infinite loading
         */
        this.$timeout(() => {
          this.getDataInProgress = false;
        }, 500);

        /*
         * save copy of getData request to use for requesting table csv report
         * Some services such as risk managment do not support as yet requested url.
         */
        this.outgoingRequestUrl = data.getRequestedUrl
          ? data.getRequestedUrl()
          : null;

        return resultData;
      });
  }

  /**
   * required params to send in fetchFn() api calls,
   * the params will be sent to the server as filters
   * can be override by a different logic
   *
   * @example
   * get requiredApiFilters () {
   *   return {
   *       "role.code": {
   *           exclude: "extapi"
   *       }
   *   };
   * }
   * @returns {{}}
   */
  get requiredApiFilters(): any {
    return {};
  }

  /**
   * save table params (page, count & sorting) in local storage.
   * filters are not saved in this method because they are handled directly in table-filter component.
   */
  setLocalStorageTableParams(): void {
    if (!this.tableCacheKey) {
      return;
    }

    const page = this.tableParams.page();
    const count = this.tableParams.count();
    const sorting = this.tableParams.sorting();

    /*
     * save contact query parameters to local storage
     * don't keep page & count when in infinite scroll mode because it's always should start form page 1
     */
    this.tableCacheService.put(
      this.tableCacheKey,
      this.isScrolling ? { sorting } : { sorting, page, count },
    );
  }

  /**
   * get table params (page, count & sorting) from local storage
   *
   * @example {count: 50, page: 2, sorting: {registrationDate: desc}}
   * @returns {object} - returns already normalized table params
   */
  getLocalStorageTableParams(): any {
    if (!this.tableCacheKey) {
      return;
    }

    return this.tableCacheService.get(this.tableCacheKey) || {};
  }

  /**
   * get normalized table filters
   *
   * @example {countryId:[1, 2], deskId3}
   * @returns {object} - returns a normalized object of filter models
   */
  getNormalizedFilters() {
    if (!this.tableKey) {
      return;
    }

    // get filter models from table-filter cache, normalize them
    const normalizedFilter = this.filterService.toFilter(
      this.filterCache.get(this.tableKey),
    );

    this.normalizedFilters = normalizedFilter;
    return normalizedFilter;
  }

  /**
   * is there any cached filters for current filter key?
   * @returns {Boolean} returns true if cached record exists on local storage
   */
  isCachedFilterAvailable(): boolean {
    if (!this.tableKey) {
      return false;
    }
    return this.filterCache.get(this.tableKey) !== undefined;
  }

  /**
   *
   * 1 reset All Checkboxes Selection
   * 2 reset checkboxes object properties
   * 3 update the selected checkboxes list
   * 4 call the function "onResetAllSelection()" - which can be override by a different logic
   */
  resetAllCheckboxesSelection(): void {
    this.checkboxes.selected = {};
    this.updateSelectedItems();
    this.toggleCheckboxesObjectProperties();
    this.onResetAllSelection();
  }

  /**
   * Update selected items when toggle Checkboxes
   *
   * convert object key to array, and filter array where "key value" / "checkbox model" is set to true - checkbox is selected
   *
   *  "the key property created dynamic with ng-repeat and set key to item.id".
   */
  updateSelectedItems(): void {
    const allCheckboxes = this.checkboxes.selected;
    // set selected properties for checkbox
    this.checkboxes.selectedToList = Object.keys(allCheckboxes) // returns an string array of the allCheckboxes object properties
      .filter((id) => allCheckboxes[id]) //  creates a new array with only the selected checkbox
      .map((id) => parseInt(id, 10));
  }

  /**
   * Loop over table records and toggle checkboxes in current page
   * @param {collection} list - Table records
   * @param {boolean} isChecked - is checkbox checked?
   */
  toggleCheckboxes(list: any[], isChecked: boolean) {
    list.forEach((item) => {
      this.checkboxes.selected[item.id] = isChecked;
    });
  }

  /**
   *  Toggle Checkboxes Object Properties - on User Paging and toggle Checkbox Item
   *
   *  example reference:
   *  this.checkboxes = { showSelectAllMsg: false,  selectAllItems: false, checked: false,  selected: {}, selectedToList: [] };
   *
   * check if all Checkbox list are Checked in Current page,
   * then set checkboxAll element in header to true
   * and show message for select all in database
   */
  toggleCheckboxesObjectProperties(event?) {
    const userEvents = this.settings.userEvents;
    if (!userEvents || !this.settings.userEvents.toggleItem) {
      return;
    }

    // Reset properties
    this.checkboxes.showSelectAllMsg = false; // hide select all options
    this.checkboxes.selectAllItems = false; // reset option select all records in database
    this.checkboxes.checked = false; // reset checkbox in header

    if (event === this.settings.userEvents.toggleItem) {
      // update selected items only when toggle checkbox
      this.updateSelectedItems();
    }

    if (this.isAllCheckboxCheckedCurrentPage()) {
      this.checkboxes.checked = true; // set checkbox in header to true
      this.checkboxes.showSelectAllMsg = true; // show select all options
    }
  }

  /**
   * parse and make chnages to loaded data as necessary
   * @param {Array} data - array contain data results from api
   * @returns {Array}
   */
  parseLoadedData(data: any[], total?: number): any[] {
    return data;
  }

  /**
   * on Load Data Event
   *
   *  must be call inside the getData promise of ngTable / when user paging
   *
   * 1 toggle Checkboxes Object Properties
   * 2 call onUserPaging for different logic if needed.
   */
  onLoadData(): void {
    const userEvents = this.settings.userEvents;
    if (userEvents && userEvents.paging !== undefined) {
      this.toggleCheckboxesObjectProperties(userEvents.paging);
    }
    this.onUserPaging();
  }

  /**
   * 1 toggle all checkbox elements in Current page, and Popup User Assign.
   * 2 update selected List
   * 3 call onToggleCheckbox for different logic if needed.
   *
   */
  toggleAllCurrentSelectedItems(): void {
    const toggleCheckAll = this.checkboxes.checked;
    const tableResult = this.tableData.currentPageResult;

    this.checkboxes.selectAllItems = false; // Reset Option select all records in database
    this.checkboxes.showSelectAllMsg = toggleCheckAll;
    this.toggleCheckboxes(tableResult, toggleCheckAll);
    this.updateSelectedItems();
    this.onToggleAllCheckbox();
  }

  /**
   * 1 toggle checkbox, Popup User Assign, checkboxAll element in header,
   * 2 update selected List.
   * 3 call onToggleCheckbox for different logic if needed.
   *
   * @param {object} item
   */
  toggleItem(item: any, event = null): void {
    const userEvents = this.settings.userEvents;
    if (!userEvents || !userEvents.toggleItem) {
      return;
    }
    // toggle value
    this.checkboxes.selected[item.id] = !this.checkboxes.selected[item.id];
    this.toggleCheckboxesObjectProperties(userEvents.toggleItem);
    this.onToggleCheckbox(item);
  }

  /**
   *  check if all Checkbox list are Checked in Current page
   *
   * @returns {boolean}
   */
  isAllCheckboxCheckedCurrentPage(): boolean {
    let isAllChecked = false;
    const tableResult = this.tableData.currentPageResult;
    const selectedItems = this.checkboxes.selectedToList;
    let count = 0;

    let i;
    let len;
    let itemObj;

    for (i = 0, len = tableResult.length, itemObj; i <= len; i = i + 1) {
      itemObj = tableResult[i];
      if (typeof itemObj === 'undefined') {
        return isAllChecked;
      }
      if (selectedItems.indexOf(itemObj.id) > -1) {
        // on loop check if id is already selected
        count = count + 1;
      }
      if (count >= len) {
        isAllChecked = true;
      }
    }
    return isAllChecked;
  }

  /**
   * Reload table
   */
  reloadTable(): void {
    this.ngTableDataParams.reload();
  }

  /**
   * on reset All Selection Event
   *
   * can be override by a different logic
   */
  onResetAllSelection(): void {}

  /**
   * on user Paging Event
   *
   * can be override by a different logic
   */
  onUserPaging(): void {}

  /**
   *  on toggle all checkboxes event
   *
   *  can be override by a different logic
   */
  onToggleAllCheckbox(): void {}

  /**
   *  on toggle checkbox event
   *
   *  can be override by a different logic
   *
   *  @param {object} obj
   */
  onToggleCheckbox(obj): void {}

  /**
   * called when selectedFilter object changes.
   * Set filter object to the table params, which will in turn make the API call
   * with the filters.
   * @param {angular.IAngularEvent} event
   * @param {any} filterModels - Json object of models
   */
  onFiltersChange(event: angular.IAngularEvent, filterModels: any): void {
    /*
     * Wanted behaviour: first catch for 'table:filter:updated' is the right one (usually the modal)
     * (we won't like to catch it in all other 'relatives')
     * 'if' - is needed when table and filters are separated and sits on dashboard parent component
     */
    if (event['stopPropagation']) {
      event.stopPropagation();
    }
    // reset table to the first page
    this.resetNgTablePage();

    const normalizedFilters = this.filterService.toFilter(filterModels);

    // setting the filter will trigger an API call with the filters (internally in ng-table)
    if (this.tableParams) {
      this.tableParams.filter(normalizedFilters);
    }

    // keep last state of filters
    this.normalizedFilters = normalizedFilters;
  }

  /**
   * override ng-table : 'sortBy' method
   * (we are not extending ng-table it's not possible to use it's methods)
   */
  sortBy($column, event): void {
    this.resetNgTablePage();

    const parsedSortable = $column.sortable && $column.sortable();
    if (!parsedSortable) {
      return;
    }

    const defaultSort = this.tableParams.settings().defaultSort;
    const inverseSort = defaultSort === 'asc' ? 'desc' : 'asc';
    const sorting =
      this.tableParams.sorting() &&
      this.tableParams.sorting()[parsedSortable] &&
      this.tableParams.sorting()[parsedSortable] === defaultSort;
    const sortingParams =
      event.ctrlKey || event.metaKey ? this.tableParams.sorting() : {};

    sortingParams[parsedSortable] = sorting ? inverseSort : defaultSort;
    this.tableParams.parameters({
      sorting: sortingParams,
    });
  }

  /**
   * reset table page number to first page
   */
  resetNgTablePage(): void {
    if (this.tableParams && this.tableParams.page) {
      this.tableParams.page(1);
    }
  }

  /**
   * open table header filter for the selected field
   * @param {String} field filter field name as it appears in filter setting file
   */
  openCustomFilter(field): void {
    this.isFilterOpen = true;
    this.chosenFilter = field;
    this.filterType = this.filtersSettings[
      this.chosenFilter
    ].popover.filterType;
  }

  /**
   * Checks whether the current column is filtered
   * @param {String} field filter field name as it appears in filter setting file
   * @returns {boolean}
   */
  isColumnFiltered(field): boolean {
    // currently open filter, show icon
    if (this.isFilterOpen && field === this.chosenFilter) {
      return true;
    }

    /*
     * get filter api property as it exists in selectedFilter
     * example: country => countryId
     */
    const apiKey = this.filterService.getFilterApiKey(field);

    return this.normalizedFilters[apiKey] !== undefined;
  }

  /**
   * Checks whether the current table table column is filterable or not
   * when true filter icon will appear on column action tooltip
   *
   * @example
   * isColumnFilterable ({field}) {
   *  let isFullObj = true;
   *  const value = this.settings.dependenciesPerFilter[field];
   *  if (value) {
   *      _.each(value, (item) => {
   *          if (!_.isNull(this[item]) && !_.isUndefined(this[item]) &&
   *          (_.isNull(this[item].id) || _.isUndefined(this[item].id))) {
   *           isFullObj = false;
   *          }
   *      });
   *  }
   *  return isFullObj;
   * }
   * @param {Object} $column ngTable column object
   * @returns {boolean} - returns true if this table column is filterable
   */
  isColumnFilterable($column): boolean {
    return $column.filterable;
  }

  /**
   * Checks whether the current table column is sortable or not
   * when true sort icon will appear on column action tooltip
   *
   * @param $column
   * @returns {boolean}
   */
  isColumnSortable($column): boolean {
    return $column.sortable();
  }

  /**
   * checks whether the current column is filtered at the moment
   * @param {String} field filter field name as it appears in filter setting file
   * @returns {boolean} returns true when filter container is opened, returns false otherwise
   */
  isCurrentFilterOpen(field): boolean {
    return this.isFilterOpen && field === this.chosenFilter;
  }

  /**
   * checks whether the current column can show popup on hover
   *
   * @param {object} $column - ng-table column object
   * @returns {boolean} - true if the column popover should be shown on hover
   */
  showColumnActions($column): boolean {
    return (
      $column.removable ||
      ($column.filterable && this.isColumnFilterable($column)) ||
      $column.sortable()
    );
  }

  /**
   * remove chosen column from the table current view
   */
  removeColumn(field): void {
    // close any open filter before removing the column
    this.isFilterOpen = false;
    this.$scope.$broadcast('table:column:removed', field);
    this.$scope.$emit('table:column:removed', field);
  }

  /**
   * merge 'filters' into cached (disabled) filters
   *
   * note, that this method designed to be called on construction time or at least at any time before
   * initTable() or table-filter component initialized.
   *
   * @example
   * replaceFilters({
   *  newContacts: {},
   *  userGroup: {id: 1, name: "BFF"}
   * })
   *
   * @param {Object} filters filter object with filter name as key and filter object as value
   * @returns {void}
   */
  replaceFilters(filters): void {
    // force disabling of all filters in local storage belongs to current cacheId
    this.filterCache.disableAll(this.tableKey);

    // add filter to cached filters in local storage
    const items = this.filterService.prepareItemForCache(filters);
    this.filterCache.addItems(this.tableKey, items);
  }

  /**
   * sets tableParams to new number of rows, override by child
   * @param {number} numOfRows is the number of rows the table will have in every page
   * @returns {void}
   */
  setNumberOfRows(numOfRows: number): void {
    // refreshes table
    this.tableParams.count(numOfRows);
  }
}

export default TableController;
