import ng from 'angular';
import {
  observeComponentLifecycles,
  observeShareCompChange,
} from '@proftit/rxjs.adjunct.ng1';
import * as rx from '@proftit/rxjs';
import * as _ from '@proftit/lodash';
import template from './crypto-deposit.component.html';
import { shareReplayRefOne, useStreams } from '@proftit/rxjs.adjunct';
import EwalletsService from '~/source/management/brand-ewallet/services/ewallets.service';
import { FormControl, FormGroup } from '@proftit/ng1.reactive-forms';
import Ewallet from '~/source/common/models/ewallet';
import TransactionController from '~/source/contact/contact-page/trading-account/common/transaction-base.controller';
import IElementRestNg from '~/source/common/models/ielement-rest-ng';
import { EntityWithCode } from '@proftit/crm.api.models.general';
import { CurrenciesService } from '../../../../../../common/services/currencies';
import { Currency, TradingAccount } from '@proftit/crm.api.models.entities';
import { PreselectType } from '~/source/common/components/dropdowns/base/component';
import { TradingAccountTransactionStatusCode } from '@proftit/crm.api.models.enums';

const styles = require('./crypto-deposit.component.scss');

export class CryptoDepositController extends TransactionController {
  static $inject = [
    'ewalletsService',
    'depositsSettings',
    'currenciesService',
    ...TransactionController.$inject,
  ];
  PreselectType = PreselectType;

  styles = styles;

  lifecycles = observeComponentLifecycles(this);
  currenciesService: CurrenciesService;
  ewalletsService: EwalletsService;
  depositsSettings;

  addDepositAction = new rx.Subject<void>();

  ewallets$ = this.streamEwallets();
  modelFormGroup$ = this.streamModelFormGroup();
  cryptoCurrencies$ = this.streamCryptoCurrencies();
  accountIsCrypto$ = this.streamAccountIsCrypto();

  /* @ngInject */
  constructor(...args) {
    super(...args);
    useStreams(
      [
        this.addDepositAction,
        this.ewallets$,
        this.streamAddDeposit(),
        this.modelFormGroup$.pipe(rx.switchMap((x) => x.value$)),
        this.cryptoCurrencies$,
        this.accountIsCrypto$,
      ],
      this.lifecycles.onDestroy$,
    );
  }

  streamCryptoCurrencies(): rx.Observable<any> {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.switchMap((x) => this.accountIsCrypto$),
      // crypto currencies are needed only when the EWallet form
      // used to deposit from non-crypto account.
      rx.filter((accountIsCrypto) => !accountIsCrypto),
      rx.switchMap((x: boolean) => {
        return this.currenciesService
          .setConfig({ blockUiRef: 'cryptoCurrencies' })
          .filter({ isCrypto: true })
          .getListWithQuery()
          .then((data) => data.plain() as Currency[])
          .catch((e) => [] as Currency[]);
      }),
      rx.filter((x) => !_.isNil(x)),
      rx.map((cryptoCurrencies: Currency[]) =>
        cryptoCurrencies.map((currency) => {
          return {
            ...currency,
            label: currency.name,
          };
        }),
      ),
      shareReplayRefOne(),
    )(null);
  }

  get depositType() {
    return this.depositsSettings.types.manualEwallet;
  }

  streamAccountIsCrypto(): rx.Observable<boolean> {
    return rx.pipe(
      () =>
        observeShareCompChange<TradingAccount>(
          this.lifecycles.onChanges$,
          'account',
        ),
      rx.filter((account) => !_.isNil(account)),
      rx.map<TradingAccount, boolean>(
        (account: TradingAccount) => account.currency.isCrypto,
      ),
    )(null);
  }

  streamEwallets() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.switchMap(() => {
        return rx.obs
          .from(this.ewalletsService.getListWithQuery())
          .pipe(rx.catchError((e) => rx.obs.NEVER));
      }),
      rx.map((ewallets: IElementRestNg<EntityWithCode>[]) =>
        ewallets.map((ewallet) => {
          return {
            ...ewallet,
            label: ewallet.name,
          };
        }),
      ),
      shareReplayRefOne(),
    )(null);
  }

  streamAddDeposit() {
    return rx.pipe(
      () => this.addDepositAction,
      rx.withLatestFrom(this.modelFormGroup$),
      rx.map(([a, formGroup]) => {
        const {
          ewallet,
          status,
          transactionCryptoCurrencyCode,
          transactionCryptoAmount,
          transactionId,
          fromEwalletAddress,
          toEwalletAddress,
          amountRequested,
          ...rest
        } = formGroup.value;
        return {
          ...rest,
          amountRequested,
          transactionStatusCode: status.code,
          transactionTransferEwallet: {
            transactionId,
            fromEwalletAddress,
            toEwalletAddress,
            currencyCode: this.account.currency.isCrypto
              ? this.account.currency.code
              : transactionCryptoCurrencyCode.code,
            ewalletId: ewallet.id,
            requestedAmount:
              transactionCryptoAmount !== null
                ? transactionCryptoAmount
                : amountRequested,
          },
        };
      }),
      rx.tap((normalizedData) => {
        super.makeDeposit(normalizedData);
      }),
      shareReplayRefOne(),
    )(null);
  }

  streamModelFormGroup() {
    return rx.pipe(
      () => this.lifecycles.onInitShared$.pipe(rx.filter((x) => x)),
      rx.map(() => {
        return new FormGroup({
          amountRequested: new FormControl<number>(null),
          status: new FormControl<string>(null),
          transactionCryptoCurrencyCode: new FormControl<string>(null),
          transactionCryptoAmount: new FormControl<number>(null),
          ewallet: new FormControl<Ewallet>(null),
          transactionId: new FormControl<string>(null),
          fromEwalletAddress: new FormControl<string>(null),
          toEwalletAddress: new FormControl<string>(null),
        } as any);
      }),
      shareReplayRefOne(),
    )(null);
  }

  preselectApprovedStatus(statuses: any[]) {
    return statuses.find(
      (item) => item.code === TradingAccountTransactionStatusCode.Approved,
    );
  }

  $onInit() {}

  $onDestroy() {}

  $onChanges() {}
}

export const CryptoDepositComponent = {
  template,
  controller: CryptoDepositController,
  bindings: {
    customer: '<',
    account: '<',
    onCancel: '&',
    onDone: '&',
  },
};
